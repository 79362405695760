import { j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import styled, { ThemeProvider } from "styled-components";
const Container = styled.div`
    /* margin-left: 3px; */
    display: ${(props) => props.vertical ? "static" : "flex"};
    overflow: auto;
    min-width: 0;
    /* flex-wrap: wrap; */
`;
const LabelContainer = styled.div`
    display: ${(props) => props.align};
    width: ${(props) => props.width == "menu" ? "var(--menuWidth)" : ""};
    align-items: ${(props) => props.alignItems};
`;
const Label = styled.p`
    font-size: 14px;
    display: ${(props) => props.labelVisible};
    margin-right: 5px;
    margin-bottom: ${(props) => props.align == "flex" ? "none" : "2px"};
`;
const actionGroup = {
  // margin: '0px -2px 0px -2px',
  borderRadius: "0",
  padding: "0px 12px 0px 10px",
  border: "1px solid var(--mainGray)",
  // Adds a light border around each button in the group
  outlineOffset: "-6px"
};
const verticalActionGroup = {
  margin: "0px 4px 0px 4px",
  borderRadius: "0",
  padding: "0px 10px 0px 10px",
  border: "1px solid var(--mainGray)",
  outlineOffset: "-6px"
};
const ActionButtonGroup = (props) => {
  let first_prop = props.vertical ? "first_vert" : "first";
  let last_prop = props.vertical ? "last_vert" : "last";
  let overflow_prop = props.width ? "no_overflow" : "overflow";
  let elem = React__default.Children.toArray(props.children);
  if (elem.length > 1) {
    elem = [
      React__default.cloneElement(elem[0], {
        num: first_prop,
        overflow: overflow_prop
      })
    ].concat(
      elem.slice(1, -1).map(
        (x) => React__default.cloneElement(x, { overflow: overflow_prop })
      )
    ).concat(
      React__default.cloneElement(elem[elem.length - 1], {
        num: last_prop,
        overflow: overflow_prop
      })
    );
  }
  const labelVisible = props.label ? "static" : "none";
  var label = "";
  var align = "flex";
  var alignItems = "center";
  if (props.label) {
    label = props.label;
    if (props.verticalLabel) {
      align = "static";
    }
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(jsxRuntimeExports.Fragment, { children: /* @__PURE__ */ jsxRuntimeExports.jsxs(
    LabelContainer,
    {
      align,
      alignItems,
      width: props.width,
      children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx(Label, { labelVisible, align, children: label }),
        /* @__PURE__ */ jsxRuntimeExports.jsx(Container, { vertical: props.vertical, children: /* @__PURE__ */ jsxRuntimeExports.jsx(
          ThemeProvider,
          {
            theme: props.vertical ? verticalActionGroup : actionGroup,
            children: elem
          }
        ) })
      ]
    }
  ) });
};
const ActionButtonGroup$1 = ActionButtonGroup;
export {
  ActionButtonGroup$1 as A
};
