import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor } from "./index-f4538070.js";
import React__default, { useEffect, createContext } from "react";
import "react-dom";
import "styled-components";
const BoardContext = createContext();
const chart = React__default.memo(function Chart(props) {
  let { name, id, SVs, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
    };
  }, []);
  useEffect(() => {
    if (SVs.dataFrame !== null) {
      let colInds;
      if (SVs.colInd !== null) {
        colInds = [SVs.colInd];
      } else {
        colInds = SVs.dataFrame.columnTypes.map((v, i) => v === "number" ? i : null).filter((x) => x !== null);
      }
      let data = [];
      for (let colInd of colInds) {
        if (SVs.type === "box") {
          data.push({
            y: extractColumn(SVs.dataFrame.data, colInd),
            type: "box",
            name: SVs.dataFrame.columnNames[colInd]
          });
        } else {
          data.push({
            x: extractColumn(SVs.dataFrame.data, colInd),
            type: "histogram",
            name: SVs.dataFrame.columnNames[colInd]
          });
        }
      }
    }
  }, []);
  return /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      VisibilitySensor,
      {
        partialVisibility: true,
        onChange: onChangeVisibility,
        children: /* @__PURE__ */ jsxRuntimeExports.jsx("div", { id })
      }
    )
  ] });
});
function extractColumn(data, colInd) {
  let dataColumn = [];
  for (let row of data) {
    if (row[colInd] !== null) {
      dataColumn.push(row[colInd]);
    }
  }
  return dataColumn;
}
export {
  BoardContext,
  chart as default
};
