import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const row = React__default.memo(function Row(props) {
  let { name, id, SVs, children } = useDoenetRenderer(props);
  if (SVs.hidden) {
    return null;
  }
  let style = {};
  if (SVs.valign !== null) {
    style.verticalAlign = SVs.valign;
  }
  if (SVs.left !== "none") {
    style.borderLeftStyle = "solid";
    if (SVs.left === "minor") {
      style.borderLeftWidth = "thin";
    } else if (SVs.left === "medium") {
      style.borderLeftWidth = "medium";
    } else {
      style.borderLeftWidth = "thick";
    }
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx("tr", { id, style, children });
});
export {
  row as default
};
