import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const lsq = React__default.memo(function Lsq(props) {
  let { SVs } = useDoenetRenderer(props, false);
  if (SVs.hidden) {
    return null;
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(jsxRuntimeExports.Fragment, { children: "‘" });
});
export {
  lsq as default
};
