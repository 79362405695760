import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const textList = React__default.memo(function TextList(props) {
  let { name, id, SVs, children } = useDoenetRenderer(props);
  if (SVs.hidden) {
    return null;
  }
  if (children.length === 0 && SVs.text) {
    return /* @__PURE__ */ jsxRuntimeExports.jsxs(React__default.Fragment, { children: [
      /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
      /* @__PURE__ */ jsxRuntimeExports.jsx("span", { id, children: SVs.text })
    ] }, id);
  }
  let withCommas = children.slice(1).reduce((a, b) => [...a, ", ", b], [children[0]]);
  return /* @__PURE__ */ jsxRuntimeExports.jsxs(React__default.Fragment, { children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    withCommas
  ] }, id);
});
export {
  textList as default
};
