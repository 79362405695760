import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor, F as FontAwesomeIcon, n as faPuzzlePiece } from "./index-f4538070.js";
import React__default, { useEffect } from "react";
import styled from "styled-components";
import { a as addCommasForCompositeRanges } from "./composites-3c240e16.js";
import "react-dom";
const SpanStyling = styled.span`
    // display: block;
    // margin: SVs.open ? 12px 4px 0px 4px : 12px 4px 12px 4px;
    // padding: 6px;
    // border: 2px solid black;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    // border-bottom-left-radius: SVs.open ? 0px : 5px;
    // border-bottom-right-radius: SVs.open ? 0px : 5px;
    // background-color: var(--mainGray);
    // cursor: pointer;
    &: focus {
        outline: 2px solid var(--canvastext);
        outline-offset: 2px;
    }
`;
const solution = React__default.memo(function Solution(props) {
  let { name, id, SVs, children, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
    };
  }, []);
  let openCloseText = "open";
  if (SVs.hidden) {
    return null;
  }
  let icon;
  let childrenToRender = null;
  let infoBlockStyle = { display: "none" };
  let onClickFunction;
  let onKeyPressFunction;
  if (SVs.open) {
    if (SVs._compositeReplacementActiveRange) {
      children = addCommasForCompositeRanges({
        children,
        compositeReplacementActiveRange: SVs._compositeReplacementActiveRange,
        startInd: 0,
        endInd: children.length - 1
      });
    }
    icon = /* @__PURE__ */ jsxRuntimeExports.jsx(FontAwesomeIcon, { icon: faPuzzlePiece });
    openCloseText = "close";
    childrenToRender = children;
    infoBlockStyle = {
      display: "block",
      margin: "0px 4px 12px 4px",
      padding: "6px",
      border: "2px solid var(--canvastext)",
      borderTop: "0px",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
      backgroundColor: "var(--canvas)"
    };
    onKeyPressFunction = (e) => {
      if (e.key === "Enter") {
        callAction({
          action: actions.closeSolution
        });
      }
    };
    if (SVs.canBeClosed) {
      onClickFunction = () => {
        callAction({
          action: actions.closeSolution
        });
      };
    } else {
      onClickFunction = () => {
      };
    }
  } else {
    icon = /* @__PURE__ */ jsxRuntimeExports.jsx(FontAwesomeIcon, { icon: faPuzzlePiece, rotation: 90 });
    onClickFunction = () => {
      callAction({
        action: actions.revealSolution
      });
    };
    onKeyPressFunction = (e) => {
      if (e.key === "Enter") {
        callAction({
          action: actions.revealSolution
        });
      }
    };
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: /* @__PURE__ */ jsxRuntimeExports.jsxs("aside", { id, style: { margin: "12px 0" }, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        /* @__PURE__ */ jsxRuntimeExports.jsxs(
          SpanStyling,
          {
            style: {
              display: "block",
              margin: SVs.open ? "12px 4px 0px 4px" : "12px 4px 12px 4px",
              padding: "6px",
              border: "2px solid var(--canvastext)",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: SVs.open ? "0px" : "5px",
              borderBottomRightRadius: SVs.open ? "0px" : "5px",
              backgroundColor: "var(--mainGray)",
              cursor: "pointer"
            },
            tabIndex: "0",
            id: id + "_button",
            onClick: onClickFunction,
            onKeyDown: onKeyPressFunction,
            children: [
              icon,
              " ",
              SVs.sectionName,
              " ",
              SVs.message,
              " (click to",
              " ",
              openCloseText,
              ")"
            ]
          }
        ),
        /* @__PURE__ */ jsxRuntimeExports.jsx("span", { style: infoBlockStyle, children: childrenToRender })
      ] })
    }
  );
});
export {
  solution as default
};
