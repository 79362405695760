import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor } from "./index-f4538070.js";
import React__default, { useEffect } from "react";
import { s as sizeToCSS } from "./css-5483d03f.js";
import "react-dom";
import "styled-components";
const summaryStatistics = React__default.memo(function Tabular(props) {
  let { name, id, SVs, children, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
    };
  }, []);
  if (SVs.hidden) {
    return null;
  }
  const tableStyle = {
    width: sizeToCSS(SVs.width),
    height: sizeToCSS(SVs.height),
    borderCollapse: "collapse",
    borderColor: "black",
    borderRadius: "var(--mainBorderRadius)"
  };
  let options = [
    "mean",
    "stdev",
    "variance",
    "stderr",
    "count",
    "minimum",
    "quartile1",
    "median",
    "quartile3",
    "maximum",
    "range",
    "sum"
  ];
  let columns = options.filter((x) => x in SVs.summaryStatistics);
  let heading = /* @__PURE__ */ jsxRuntimeExports.jsx("tr", { children: columns.map((x, i) => /* @__PURE__ */ jsxRuntimeExports.jsx("th", { children: x }, i)) });
  let data = /* @__PURE__ */ jsxRuntimeExports.jsx("tr", { children: columns.map((x, i) => /* @__PURE__ */ jsxRuntimeExports.jsx("td", { children: SVs.summaryStatistics[x] }, i)) });
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: /* @__PURE__ */ jsxRuntimeExports.jsxs("div", { style: { margin: "12px 0" }, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        /* @__PURE__ */ jsxRuntimeExports.jsxs("p", { children: [
          "Summary statistics of ",
          SVs.columnName
        ] }),
        /* @__PURE__ */ jsxRuntimeExports.jsx("table", { id, style: tableStyle, children: /* @__PURE__ */ jsxRuntimeExports.jsxs("tbody", { children: [
          heading,
          data
        ] }) })
      ] })
    }
  );
});
export {
  summaryStatistics as default
};
