import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor, F as FontAwesomeIcon } from "./index-f4538070.js";
import React__default, { useEffect } from "react";
import { f as faComment } from "./index-999c2cad.js";
import styled from "styled-components";
import { a as addCommasForCompositeRanges } from "./composites-3c240e16.js";
import "react-dom";
const FeedbackStyling = styled.aside`
    background-color: var(--canvas);
    margin: 0px 4px 12px 4px;
    padding: 1em;
    border: 2px solid var(--canvastext);
    border-top: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    //   &: focus {
    //   outline: 2px solid var(--canvastext);
    //   outline-offset: 2px;
    //  }
`;
const SpanStyling = styled.span`
    display: block;
    margin: 12px 4px 0px 4px;
    padding: 6px;
    border: 2px solid var(--canvastext);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--mainGray);
    &: focus {
        outline: 2px solid var(--canvastext);
        outline-offset: 2px;
    }
`;
const feedback = React__default.memo(function Feedback(props) {
  let { name, id, SVs, children, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
    };
  }, []);
  if (SVs.hidden) {
    return null;
  }
  let icon = /* @__PURE__ */ jsxRuntimeExports.jsx(FontAwesomeIcon, { icon: faComment });
  if (SVs._compositeReplacementActiveRange) {
    children = addCommasForCompositeRanges({
      children,
      compositeReplacementActiveRange: SVs._compositeReplacementActiveRange,
      startInd: 0,
      endInd: children.length - 1
    });
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
        /* @__PURE__ */ jsxRuntimeExports.jsxs(SpanStyling, { tabIndex: "0", children: [
          icon,
          " Feedback"
        ] }),
        /* @__PURE__ */ jsxRuntimeExports.jsxs(
          FeedbackStyling,
          {
            id,
            children: [
              /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
              SVs.feedbackText,
              children
            ]
          }
        )
      ] })
    }
  );
});
export {
  feedback as default
};
