import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import { a as addCommasForCompositeRanges } from "./composites-3c240e16.js";
import "react-dom";
import "styled-components";
const em = React__default.memo(function Em(props) {
  let { name, id, SVs, children } = useDoenetRenderer(props);
  if (SVs.hidden) {
    return null;
  }
  if (SVs._compositeReplacementActiveRange) {
    children = addCommasForCompositeRanges({
      children,
      compositeReplacementActiveRange: SVs._compositeReplacementActiveRange,
      startInd: 0,
      endInd: children.length - 1
    });
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsxs("em", { id, children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    children
  ] });
});
export {
  em as default
};
