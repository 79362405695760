import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor } from "./index-f4538070.js";
import React__default, { useEffect } from "react";
import { a as addCommasForCompositeRanges } from "./composites-3c240e16.js";
import "react-dom";
import "styled-components";
const blockQuote = React__default.memo(function Container(props) {
  let { name, id, SVs, children, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    if (actions.recordVisibilityChange) {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible }
      });
    }
  };
  useEffect(() => {
    return () => {
      if (actions.recordVisibilityChange) {
        callAction({
          action: actions.recordVisibilityChange,
          args: { isVisible: false }
        });
      }
    };
  }, []);
  if (SVs.hidden) {
    return null;
  }
  if (SVs._compositeReplacementActiveRange) {
    children = addCommasForCompositeRanges({
      children,
      compositeReplacementActiveRange: SVs._compositeReplacementActiveRange,
      startInd: 0,
      endInd: children.length - 1
    });
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: /* @__PURE__ */ jsxRuntimeExports.jsxs("blockquote", { id, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        children
      ] })
    }
  );
});
export {
  blockQuote as default
};
