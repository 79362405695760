import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const _error = React__default.memo(function Error(props) {
  let { id, SVs, children } = useDoenetRenderer(props);
  let displayedMessage = null;
  if (SVs.showMessage) {
    let errorStyle = {
      backgroundColor: "#ff9999",
      textAlign: "center",
      borderWidth: 3,
      borderStyle: "solid"
    };
    let rangeMessage = null;
    if (SVs.rangeMessage) {
      rangeMessage = /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("br", {}),
        /* @__PURE__ */ jsxRuntimeExports.jsx("em", { children: SVs.rangeMessage })
      ] });
    }
    displayedMessage = /* @__PURE__ */ jsxRuntimeExports.jsxs("div", { style: errorStyle, children: [
      /* @__PURE__ */ jsxRuntimeExports.jsx("b", { children: "Error" }),
      ": ",
      SVs.message,
      rangeMessage
    ] });
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsxs("div", { id, children: [
    displayedMessage,
    children
  ] });
});
export {
  _error as default
};
