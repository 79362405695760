import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const tag = React__default.memo(function Tag(props) {
  let { name, id, SVs, children } = useDoenetRenderer(props);
  if (SVs.hidden) {
    return null;
  }
  let open = "<";
  let close = ">";
  if (SVs.selfClosed) {
    close = "/>";
  } else if (SVs.closing) {
    open = "</";
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsxs("code", { id, style: { color: "var(--mainGreen)" }, children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    open,
    children,
    close
  ] });
});
export {
  tag as default
};
