import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const ellipsis = React__default.memo(function Ellipsis(props) {
  let { name, id, SVs } = useDoenetRenderer(props);
  if (SVs.hidden) {
    return null;
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    "…"
  ] });
});
export {
  ellipsis as default
};
