import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor } from "./index-f4538070.js";
import React__default, { useEffect } from "react";
import { s as sizeToCSS } from "./css-5483d03f.js";
import "react-dom";
import "styled-components";
const tabular = React__default.memo(function Tabular(props) {
  let { name, id, SVs, children, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
    };
  }, []);
  if (SVs.hidden) {
    return null;
  }
  const tableStyle = {
    width: sizeToCSS(SVs.width),
    height: sizeToCSS(SVs.height),
    borderCollapse: "collapse",
    borderColor: "var(--canvastext)",
    borderRadius: "var(--mainBorderRadius)",
    tableLayout: "fixed"
  };
  if (SVs.top !== "none") {
    tableStyle.borderTopStyle = "solid";
    if (SVs.top === "minor") {
      tableStyle.borderTopWidth = "thin";
    } else if (SVs.top === "medium") {
      tableStyle.borderTopWidth = "medium";
    } else {
      tableStyle.borderTopWidth = "thick";
    }
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: /* @__PURE__ */ jsxRuntimeExports.jsxs("div", { style: { margin: "12px 0" }, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        /* @__PURE__ */ jsxRuntimeExports.jsx("table", { id, style: tableStyle, children: /* @__PURE__ */ jsxRuntimeExports.jsx("tbody", { children }) })
      ] })
    }
  );
});
export {
  tabular as default
};
