import { u as useDoenetRenderer, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default, { useState } from "react";
import "react-dom";
import "styled-components";
const footnote = React__default.memo(function Footnote(props) {
  let { name, id, SVs } = useDoenetRenderer(props, false);
  let [isVisible, setIsVisible] = useState(false);
  if (SVs.hidden) {
    return null;
  }
  const footnoteMessageStyle = {
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#e2e2e2",
    display: `static`
  };
  let footnoteMessage = "";
  if (isVisible) {
    footnoteMessage = /* @__PURE__ */ jsxRuntimeExports.jsx("div", { style: footnoteMessageStyle, children: SVs.text });
  }
  const buttonStyle = {
    backgroundColor: "white",
    border: "none"
  };
  const footnoteStyle = {
    textDecoration: "none",
    color: "#1A5A99"
  };
  return /* @__PURE__ */ jsxRuntimeExports.jsx(jsxRuntimeExports.Fragment, { children: /* @__PURE__ */ jsxRuntimeExports.jsxs("span", { id, children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    /* @__PURE__ */ jsxRuntimeExports.jsx("sup", { children: /* @__PURE__ */ jsxRuntimeExports.jsx(
      "button",
      {
        style: buttonStyle,
        onClick: () => setIsVisible((was) => !was),
        children: /* @__PURE__ */ jsxRuntimeExports.jsxs("a", { href: "#", title: SVs.text, style: footnoteStyle, children: [
          "[",
          SVs.footnoteTag,
          "]"
        ] })
      }
    ) }),
    footnoteMessage
  ] }) });
});
export {
  footnote as default
};
