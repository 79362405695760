import { u as useDoenetRenderer, P as PageContext, k as getURLFromRef, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default, { useContext } from "react";
import { L as Link } from "./index-200d8801.js";
import styled from "styled-components";
import "react-dom";
const RefButton = styled.button`
    position: relative;
    height: 24px;
    display: inline-block;
    color: white;
    color: ${(props) => props.disabled ? "var(--canvastext)" : "var(--canvas)"};
    background-color: ${(props) => props.disabled ? "var(--mainGray)" : "var(--mainBlue)"};

    padding: 2px;
    border: none;
    border-radius: var(--mainBorderRadius);
    cursor: pointer;
    cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
    padding: 1px 6px 1px 6px;

    &:hover {
        background-color: ${(props) => props.disabled ? "var(--mainGray)" : "var(--lightBlue)"};
        color: ${(props) => props.disabled ? "var(--canvastext)" : "var(--canvas)"};
    }

    &:focus {
        outline: 2px solid var(--mainBlue);
        outline-offset: 2px;
    }
`;
const ref = React__default.memo(function Ref(props) {
  let { name, id, SVs, children } = useDoenetRenderer(props);
  let {
    location = {},
    navigate,
    linkSettings,
    scrollableContainer
  } = useContext(PageContext) || {};
  let search = location.search || "";
  if (SVs.hidden) {
    return null;
  }
  let linkContent = children;
  if (children.length === 0) {
    linkContent = SVs.linkText;
  }
  let { targetForATag, url, haveValidTarget, externalUri } = getURLFromRef({
    cid: SVs.cid,
    activityId: SVs.activityId,
    variantIndex: SVs.variantIndex,
    edit: SVs.edit,
    hash: SVs.hash,
    page: SVs.page,
    givenUri: SVs.uri,
    targetName: SVs.targetName,
    linkSettings,
    search,
    id
  });
  if (SVs.createButton) {
    if (targetForATag === "_blank") {
      return /* @__PURE__ */ jsxRuntimeExports.jsxs("span", { id, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        /* @__PURE__ */ jsxRuntimeExports.jsx(
          RefButton,
          {
            id: id + "_button",
            onClick: () => window.open(url, targetForATag),
            disabled: SVs.disabled,
            children: SVs.linkText
          }
        )
      ] });
    } else {
      return /* @__PURE__ */ jsxRuntimeExports.jsxs("span", { id, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        /* @__PURE__ */ jsxRuntimeExports.jsx(
          RefButton,
          {
            id: id + "_button",
            onClick: () => navigate(url),
            disabled: SVs.disabled,
            children: SVs.linkText
          }
        )
      ] });
    }
  } else {
    if (haveValidTarget) {
      if (externalUri || url === "#") {
        return /* @__PURE__ */ jsxRuntimeExports.jsx(
          "a",
          {
            style: {
              color: "var(--mainBlue)",
              borderRadius: "5px"
            },
            target: targetForATag,
            id,
            name: id,
            href: url,
            children: linkContent
          }
        );
      } else {
        let scrollAttribute = scrollableContainer === window ? "scrollY" : "scrollTop";
        let stateObj = { fromLink: true };
        Object.defineProperty(stateObj, "previousScrollPosition", {
          get: () => scrollableContainer == null ? void 0 : scrollableContainer[scrollAttribute],
          enumerable: true
        });
        return /* @__PURE__ */ jsxRuntimeExports.jsx(
          Link,
          {
            style: {
              color: "var(--mainBlue)",
              borderRadius: "5px"
            },
            target: targetForATag,
            id,
            name: id,
            to: url,
            state: stateObj,
            children: linkContent
          }
        );
      }
    } else {
      return /* @__PURE__ */ jsxRuntimeExports.jsx("span", { id, children: linkContent });
    }
  }
});
export {
  ref as default
};
