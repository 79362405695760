import { e as cesc, j as jsxRuntimeExports } from "./index-f4538070.js";
import React__default from "react";
function addCommasForCompositeRanges({
  compositeReplacementActiveRange,
  children,
  startInd,
  endInd,
  removedInd = null
}) {
  let result = addCommasForCompositeRangesSub({
    compositeReplacementActiveRange,
    children,
    startInd,
    endInd,
    removedInd
  });
  return result.newChildren;
}
function addCommasForCompositeRangesSub({
  compositeReplacementActiveRange,
  children,
  startInd,
  endInd,
  removedInd = null,
  potentialListComponents = null
}) {
  let newChildren = [];
  let newPotentialListComponents = [];
  let lastChildInd = startInd - 1;
  for (let rangeInd = 0; rangeInd < compositeReplacementActiveRange.length; rangeInd++) {
    let range = compositeReplacementActiveRange[rangeInd];
    let rangeFirstInd = range.firstInd;
    let rangeLastInd = range.lastInd;
    if (removedInd !== null) {
      if (rangeFirstInd === removedInd || rangeLastInd === removedInd) {
        continue;
      }
      if (rangeFirstInd > removedInd) {
        rangeFirstInd -= 1;
      }
      if (rangeLastInd > removedInd) {
        rangeLastInd -= 1;
      }
    }
    if (rangeFirstInd > lastChildInd && rangeLastInd <= endInd) {
      if (lastChildInd + 1 < rangeFirstInd) {
        newChildren.push(
          ...children.slice(lastChildInd + 1, rangeFirstInd)
        );
        if (potentialListComponents) {
          newPotentialListComponents.push(
            ...potentialListComponents.slice(
              lastChildInd - startInd + 1,
              rangeFirstInd - startInd
            )
          );
        }
      }
      let subReplacementRange = compositeReplacementActiveRange.slice(
        rangeInd + 1
      );
      let {
        newChildren: childrenInRange,
        newPotentialListComponents: potentialListComponentsInRange
      } = addCommasForCompositeRangesSub({
        compositeReplacementActiveRange: subReplacementRange,
        children,
        startInd: rangeFirstInd,
        endInd: rangeLastInd,
        removedInd,
        potentialListComponents: range.potentialListComponents
      });
      potentialListComponentsInRange = potentialListComponentsInRange.filter(
        (x, i) => childrenInRange[i] !== null
      );
      childrenInRange = childrenInRange.filter((x) => x !== null);
      let allListComponents = potentialListComponentsInRange.every(
        (x) => x
      );
      let isBlankStringChild = childrenInRange.map(
        (child) => typeof child === "string" && child.trim() === ""
      );
      if (range.asList && allListComponents && childrenInRange.length > 1) {
        let newChildrenInRange = [childrenInRange[0]];
        for (let [prevInd, child] of childrenInRange.slice(1).entries()) {
          if (!isBlankStringChild[prevInd] && isBlankStringChild.slice(prevInd + 1).some((x) => !x)) {
            newChildrenInRange[newChildrenInRange.length - 1] = removeEndingBlankString(
              newChildrenInRange[newChildrenInRange.length - 1]
            );
            newChildrenInRange.push(", ");
          }
          newChildrenInRange.push(child);
        }
        childrenInRange = newChildrenInRange;
      }
      if (childrenInRange.length > 0) {
        let compositeId = cesc(range.compositeName);
        childrenInRange = /* @__PURE__ */ jsxRuntimeExports.jsxs(React__default.Fragment, { children: [
          /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: compositeId }),
          /* @__PURE__ */ jsxRuntimeExports.jsx("span", { id: compositeId, children: childrenInRange })
        ] }, compositeId);
        newChildren.push(childrenInRange);
        if (potentialListComponents) {
          newPotentialListComponents.push(allListComponents);
        }
      }
      lastChildInd = rangeLastInd;
    }
  }
  if (lastChildInd < endInd) {
    newChildren.push(...children.slice(lastChildInd + 1, endInd + 1));
    if (potentialListComponents) {
      newPotentialListComponents.push(
        ...potentialListComponents.slice(
          lastChildInd - startInd + 1,
          endInd - startInd + 1
        )
      );
    }
  }
  return { newChildren, newPotentialListComponents };
}
function removeEndingBlankString(component) {
  var _a, _b;
  if (typeof component === "string") {
    return component.trimEnd();
  }
  if (!(((_b = (_a = component.props) == null ? void 0 : _a.children) == null ? void 0 : _b.length) > 0)) {
    return component;
  }
  let children = [...component.props.children];
  let originalLastChild = children[children.length - 1];
  let lastChild = originalLastChild;
  while (typeof lastChild === "string" && lastChild.trim() === "") {
    children.pop();
    if (children.length === 0) {
      component = { ...component };
      component.props = { ...component.props };
      component.props.children = [];
      return component;
    }
    lastChild = children[children.length - 1];
  }
  lastChild = removeEndingBlankString(lastChild);
  if (lastChild !== originalLastChild) {
    component = { ...component };
    component.props = { ...component.props };
    component.props.children = [
      ...children.slice(0, children.length - 1),
      lastChild
    ];
  }
  return component;
}
export {
  addCommasForCompositeRanges as a
};
