import { u as useDoenetRenderer, j as jsxRuntimeExports, B as Button } from "./index-f4538070.js";
import React__default from "react";
import "react-dom";
import "styled-components";
const paginatorControls = React__default.memo(function PaginatorControls(props) {
  let { name, id, SVs, actions, callAction } = useDoenetRenderer(
    props,
    false
  );
  if (SVs.hidden) {
    return null;
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsxs("p", { id, children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    /* @__PURE__ */ jsxRuntimeExports.jsx("div", { id, margin: "12px 0", style: { display: "inline-block" }, children: /* @__PURE__ */ jsxRuntimeExports.jsx(
      Button,
      {
        id: id + "_previous",
        onClick: () => {
          callAction({
            action: actions.setPage,
            args: { number: SVs.currentPage - 1 }
          });
        },
        disabled: SVs.disabled || !(SVs.currentPage > 1),
        value: SVs.previousLabel
      }
    ) }),
    " " + SVs.pageLabel,
    " ",
    SVs.currentPage,
    " of ",
    SVs.numPages + " ",
    /* @__PURE__ */ jsxRuntimeExports.jsx("div", { id, margin: "12px 0", style: { display: "inline-block" }, children: /* @__PURE__ */ jsxRuntimeExports.jsx(
      Button,
      {
        id: id + "_next",
        onClick: () => {
          callAction({
            action: actions.setPage,
            args: { number: SVs.currentPage + 1 }
          });
        },
        disabled: SVs.disabled || !(SVs.currentPage < SVs.numPages),
        value: SVs.nextLabel
      }
    ) })
  ] });
});
export {
  paginatorControls as default
};
