import { u as useDoenetRenderer, j as jsxRuntimeExports, V as VisibilitySensor } from "./index-f4538070.js";
import React__default, { useEffect } from "react";
import { a as addCommasForCompositeRanges } from "./composites-3c240e16.js";
import "react-dom";
import "styled-components";
const pre = React__default.memo(function Pre(props) {
  let { name, id, SVs, children, actions, callAction } = useDoenetRenderer(props);
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
    };
  }, []);
  if (SVs.hidden)
    return null;
  if (SVs._compositeReplacementActiveRange) {
    children = addCommasForCompositeRanges({
      children,
      compositeReplacementActiveRange: SVs._compositeReplacementActiveRange,
      startInd: 0,
      endInd: children.length - 1
    });
  }
  for (let ind of SVs.displayDoenetMLIndices) {
    let prevChild = children[ind - 1];
    if (typeof prevChild === "string") {
      let lastLineBreak = prevChild.lastIndexOf("\n");
      if (/^\s*$/.test(prevChild.slice(lastLineBreak + 1))) {
        prevChild = prevChild.slice(0, lastLineBreak + 1);
        children[ind - 1] = prevChild;
      }
    }
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: /* @__PURE__ */ jsxRuntimeExports.jsxs("pre", { id, style: { margin: "12px 0" }, children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
        children
      ] })
    }
  );
});
export {
  pre as default
};
