import { Q as useEventListener, a1 as forwardRef, a2 as cx, a3 as useStyleConfig, j as jsxRuntimeExports, a4 as chakra, a5 as createContext, a6 as useMultiStyleConfig, a7 as omitThemingProps, a8 as getValidChildren, a9 as useDisclosure, aa as usePopper, ab as lazyDisclosure, ac as cssVars, ad as mergeRefs, ae as callAllHandlers, af as useTheme, ag as runIfFn, ah as motion, ai as callAll, u as useDoenetRenderer, R as Recoil_index_24, r as rendererState, aj as Box, H as CodeMirror, V as VisibilitySensor, ak as Flex } from "./index-f4538070.js";
import React__default, { Children, cloneElement, useRef, useState, useId, useCallback, useEffect } from "react";
import { s as sizeToCSS } from "./css-5483d03f.js";
import { u as useAnimationState, a as useFocusOnHide, b as useFocusOnShow, W as WarningTwoIcon } from "./chunk-V3VOVZTR-22ec0198.js";
import "react-dom";
import "styled-components";
function isRefObject(val) {
  return "current" in val;
}
var isDom = () => typeof window !== "undefined";
function getPlatform() {
  var _a;
  const agent = navigator.userAgentData;
  return (_a = agent == null ? void 0 : agent.platform) != null ? _a : navigator.platform;
}
var vn = (v) => isDom() && v.test(navigator.vendor);
var pt = (v) => isDom() && v.test(getPlatform());
var isApple = () => pt(/mac|iphone|ipad|ipod/i);
var isSafari = () => isApple() && vn(/apple/i);
function useFocusOnPointerDown(props) {
  const { ref, elements, enabled } = props;
  const doc = () => {
    var _a, _b;
    return (_b = (_a = ref.current) == null ? void 0 : _a.ownerDocument) != null ? _b : document;
  };
  useEventListener(doc, "pointerdown", (event) => {
    if (!isSafari() || !enabled)
      return;
    const target = event.target;
    const els = elements != null ? elements : [ref];
    const isValidTarget = els.some((elementOrRef) => {
      const el = isRefObject(elementOrRef) ? elementOrRef.current : elementOrRef;
      return (el == null ? void 0 : el.contains(target)) || el === target;
    });
    if (doc().activeElement !== target && isValidTarget) {
      event.preventDefault();
      target.focus();
    }
  });
}
var fallbackIcon$1 = {
  path: /* @__PURE__ */ jsxRuntimeExports.jsxs("g", { stroke: "currentColor", strokeWidth: "1.5", children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      "path",
      {
        strokeLinecap: "round",
        fill: "none",
        d: "M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
      }
    ),
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      "path",
      {
        fill: "currentColor",
        strokeLinecap: "round",
        d: "M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
      }
    ),
    /* @__PURE__ */ jsxRuntimeExports.jsx("circle", { fill: "none", strokeMiterlimit: "10", cx: "12", cy: "12", r: "11.25" })
  ] }),
  viewBox: "0 0 24 24"
};
var Icon$1 = forwardRef((props, ref) => {
  const {
    as: element,
    viewBox,
    color = "currentColor",
    focusable = false,
    children,
    className,
    __css,
    ...rest
  } = props;
  const _className = cx("chakra-icon", className);
  const customStyles = useStyleConfig("Icon", props);
  const styles = {
    w: "1em",
    h: "1em",
    display: "inline-block",
    lineHeight: "1em",
    flexShrink: 0,
    color,
    ...__css,
    ...customStyles
  };
  const shared = {
    ref,
    focusable,
    className: _className,
    __css: styles
  };
  const _viewBox = viewBox != null ? viewBox : fallbackIcon$1.viewBox;
  if (element && typeof element !== "string") {
    return /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.svg, { as: element, ...shared, ...rest });
  }
  const _path = children != null ? children : fallbackIcon$1.path;
  return /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.svg, { verticalAlign: "middle", viewBox: _viewBox, ...shared, ...rest, children: _path });
});
Icon$1.displayName = "Icon";
var [ListStylesProvider, useListStyles] = createContext({
  name: `ListStylesContext`,
  errorMessage: `useListStyles returned is 'undefined'. Seems you forgot to wrap the components in "<List />" `
});
var List = forwardRef(function List2(props, ref) {
  const styles = useMultiStyleConfig("List", props);
  const {
    children,
    styleType = "none",
    stylePosition,
    spacing,
    ...rest
  } = omitThemingProps(props);
  const validChildren = getValidChildren(children);
  const selector = "& > *:not(style) ~ *:not(style)";
  const spacingStyle = spacing ? { [selector]: { mt: spacing } } : {};
  return /* @__PURE__ */ jsxRuntimeExports.jsx(ListStylesProvider, { value: styles, children: /* @__PURE__ */ jsxRuntimeExports.jsx(
    chakra.ul,
    {
      ref,
      listStyleType: styleType,
      listStylePosition: stylePosition,
      role: "list",
      __css: { ...styles.container, ...spacingStyle },
      ...rest,
      children: validChildren
    }
  ) });
});
List.displayName = "List";
var OrderedList = forwardRef((props, ref) => {
  const { as, ...rest } = props;
  return /* @__PURE__ */ jsxRuntimeExports.jsx(List, { ref, as: "ol", styleType: "decimal", marginStart: "1em", ...rest });
});
OrderedList.displayName = "OrderedList";
var UnorderedList = forwardRef(function UnorderedList2(props, ref) {
  const { as, ...rest } = props;
  return /* @__PURE__ */ jsxRuntimeExports.jsx(List, { ref, as: "ul", styleType: "initial", marginStart: "1em", ...rest });
});
UnorderedList.displayName = "UnorderedList";
var ListItem = forwardRef(function ListItem2(props, ref) {
  const styles = useListStyles();
  return /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.li, { ref, ...props, __css: styles.item });
});
ListItem.displayName = "ListItem";
var ListIcon = forwardRef(function ListIcon2(props, ref) {
  const styles = useListStyles();
  return /* @__PURE__ */ jsxRuntimeExports.jsx(Icon$1, { ref, role: "presentation", ...props, __css: styles.icon });
});
ListIcon.displayName = "ListIcon";
var [PopoverProvider, usePopoverContext] = createContext({
  name: "PopoverContext",
  errorMessage: "usePopoverContext: `context` is undefined. Seems you forgot to wrap all popover components within `<Popover />`"
});
var [PopoverStylesProvider, usePopoverStyles] = createContext({
  name: `PopoverStylesContext`,
  errorMessage: `usePopoverStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Popover />" `
});
var PopoverHeader = forwardRef(
  function PopoverHeader2(props, ref) {
    const { getHeaderProps } = usePopoverContext();
    const styles = usePopoverStyles();
    return /* @__PURE__ */ jsxRuntimeExports.jsx(
      chakra.header,
      {
        ...getHeaderProps(props, ref),
        className: cx("chakra-popover__header", props.className),
        __css: styles.header
      }
    );
  }
);
PopoverHeader.displayName = "PopoverHeader";
function PopoverTrigger(props) {
  const child = Children.only(props.children);
  const { getTriggerProps } = usePopoverContext();
  return cloneElement(child, getTriggerProps(child.props, child.ref));
}
PopoverTrigger.displayName = "PopoverTrigger";
var TRIGGER = {
  click: "click",
  hover: "hover"
};
function usePopover(props = {}) {
  const {
    closeOnBlur = true,
    closeOnEsc = true,
    initialFocusRef,
    id,
    returnFocusOnClose = true,
    autoFocus = true,
    arrowSize,
    arrowShadowColor,
    trigger = TRIGGER.click,
    openDelay = 200,
    closeDelay = 200,
    isLazy,
    lazyBehavior = "unmount",
    computePositionOnMount,
    ...popperProps
  } = props;
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure(props);
  const anchorRef = useRef(null);
  const triggerRef = useRef(null);
  const popoverRef = useRef(null);
  const isHoveringRef = useRef(false);
  const hasBeenOpened = useRef(false);
  if (isOpen) {
    hasBeenOpened.current = true;
  }
  const [hasHeader, setHasHeader] = useState(false);
  const [hasBody, setHasBody] = useState(false);
  const uuid = useId();
  const uid = id != null ? id : uuid;
  const [triggerId, popoverId, headerId, bodyId] = [
    "popover-trigger",
    "popover-content",
    "popover-header",
    "popover-body"
  ].map((id2) => `${id2}-${uid}`);
  const {
    referenceRef,
    getArrowProps,
    getPopperProps,
    getArrowInnerProps,
    forceUpdate
  } = usePopper({
    ...popperProps,
    enabled: isOpen || !!computePositionOnMount
  });
  const animated = useAnimationState({ isOpen, ref: popoverRef });
  useFocusOnPointerDown({
    enabled: isOpen,
    ref: triggerRef
  });
  useFocusOnHide(popoverRef, {
    focusRef: triggerRef,
    visible: isOpen,
    shouldFocus: returnFocusOnClose && trigger === TRIGGER.click
  });
  useFocusOnShow(popoverRef, {
    focusRef: initialFocusRef,
    visible: isOpen,
    shouldFocus: autoFocus && trigger === TRIGGER.click
  });
  const shouldRenderChildren = lazyDisclosure({
    wasSelected: hasBeenOpened.current,
    enabled: isLazy,
    mode: lazyBehavior,
    isSelected: animated.present
  });
  const getPopoverProps = useCallback(
    (props2 = {}, _ref = null) => {
      const popoverProps = {
        ...props2,
        style: {
          ...props2.style,
          transformOrigin: cssVars.transformOrigin.varRef,
          [cssVars.arrowSize.var]: arrowSize ? `${arrowSize}px` : void 0,
          [cssVars.arrowShadowColor.var]: arrowShadowColor
        },
        ref: mergeRefs(popoverRef, _ref),
        children: shouldRenderChildren ? props2.children : null,
        id: popoverId,
        tabIndex: -1,
        role: "dialog",
        onKeyDown: callAllHandlers(props2.onKeyDown, (event) => {
          if (closeOnEsc && event.key === "Escape") {
            onClose();
          }
        }),
        onBlur: callAllHandlers(props2.onBlur, (event) => {
          const relatedTarget = getRelatedTarget(event);
          const targetIsPopover = contains(popoverRef.current, relatedTarget);
          const targetIsTrigger = contains(triggerRef.current, relatedTarget);
          const isValidBlur = !targetIsPopover && !targetIsTrigger;
          if (isOpen && closeOnBlur && isValidBlur) {
            onClose();
          }
        }),
        "aria-labelledby": hasHeader ? headerId : void 0,
        "aria-describedby": hasBody ? bodyId : void 0
      };
      if (trigger === TRIGGER.hover) {
        popoverProps.role = "tooltip";
        popoverProps.onMouseEnter = callAllHandlers(props2.onMouseEnter, () => {
          isHoveringRef.current = true;
        });
        popoverProps.onMouseLeave = callAllHandlers(
          props2.onMouseLeave,
          (event) => {
            if (event.nativeEvent.relatedTarget === null) {
              return;
            }
            isHoveringRef.current = false;
            setTimeout(() => onClose(), closeDelay);
          }
        );
      }
      return popoverProps;
    },
    [
      shouldRenderChildren,
      popoverId,
      hasHeader,
      headerId,
      hasBody,
      bodyId,
      trigger,
      closeOnEsc,
      onClose,
      isOpen,
      closeOnBlur,
      closeDelay,
      arrowShadowColor,
      arrowSize
    ]
  );
  const getPopoverPositionerProps = useCallback(
    (props2 = {}, forwardedRef = null) => getPopperProps(
      {
        ...props2,
        style: {
          visibility: isOpen ? "visible" : "hidden",
          ...props2.style
        }
      },
      forwardedRef
    ),
    [isOpen, getPopperProps]
  );
  const getAnchorProps = useCallback(
    (props2, _ref = null) => {
      return {
        ...props2,
        // If anchor is rendered, it is used as reference.
        ref: mergeRefs(_ref, anchorRef, referenceRef)
      };
    },
    [anchorRef, referenceRef]
  );
  const openTimeout = useRef();
  const closeTimeout = useRef();
  const maybeReferenceRef = useCallback(
    (node) => {
      if (anchorRef.current == null) {
        referenceRef(node);
      }
    },
    [referenceRef]
  );
  const getTriggerProps = useCallback(
    (props2 = {}, _ref = null) => {
      const triggerProps = {
        ...props2,
        ref: mergeRefs(triggerRef, _ref, maybeReferenceRef),
        id: triggerId,
        "aria-haspopup": "dialog",
        "aria-expanded": isOpen,
        "aria-controls": popoverId
      };
      if (trigger === TRIGGER.click) {
        triggerProps.onClick = callAllHandlers(props2.onClick, onToggle);
      }
      if (trigger === TRIGGER.hover) {
        triggerProps.onFocus = callAllHandlers(props2.onFocus, () => {
          if (openTimeout.current === void 0) {
            onOpen();
          }
        });
        triggerProps.onBlur = callAllHandlers(props2.onBlur, (event) => {
          const relatedTarget = getRelatedTarget(event);
          const isValidBlur = !contains(popoverRef.current, relatedTarget);
          if (isOpen && closeOnBlur && isValidBlur) {
            onClose();
          }
        });
        triggerProps.onKeyDown = callAllHandlers(props2.onKeyDown, (event) => {
          if (event.key === "Escape") {
            onClose();
          }
        });
        triggerProps.onMouseEnter = callAllHandlers(props2.onMouseEnter, () => {
          isHoveringRef.current = true;
          openTimeout.current = window.setTimeout(() => onOpen(), openDelay);
        });
        triggerProps.onMouseLeave = callAllHandlers(props2.onMouseLeave, () => {
          isHoveringRef.current = false;
          if (openTimeout.current) {
            clearTimeout(openTimeout.current);
            openTimeout.current = void 0;
          }
          closeTimeout.current = window.setTimeout(() => {
            if (isHoveringRef.current === false) {
              onClose();
            }
          }, closeDelay);
        });
      }
      return triggerProps;
    },
    [
      triggerId,
      isOpen,
      popoverId,
      trigger,
      maybeReferenceRef,
      onToggle,
      onOpen,
      closeOnBlur,
      onClose,
      openDelay,
      closeDelay
    ]
  );
  useEffect(() => {
    return () => {
      if (openTimeout.current) {
        clearTimeout(openTimeout.current);
      }
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, []);
  const getHeaderProps = useCallback(
    (props2 = {}, ref = null) => ({
      ...props2,
      id: headerId,
      ref: mergeRefs(ref, (node) => {
        setHasHeader(!!node);
      })
    }),
    [headerId]
  );
  const getBodyProps = useCallback(
    (props2 = {}, ref = null) => ({
      ...props2,
      id: bodyId,
      ref: mergeRefs(ref, (node) => {
        setHasBody(!!node);
      })
    }),
    [bodyId]
  );
  return {
    forceUpdate,
    isOpen,
    onAnimationComplete: animated.onComplete,
    onClose,
    getAnchorProps,
    getArrowProps,
    getArrowInnerProps,
    getPopoverPositionerProps,
    getPopoverProps,
    getTriggerProps,
    getHeaderProps,
    getBodyProps
  };
}
function contains(parent, child) {
  return parent === child || (parent == null ? void 0 : parent.contains(child));
}
function getRelatedTarget(event) {
  var _a;
  const activeEl = event.currentTarget.ownerDocument.activeElement;
  return (_a = event.relatedTarget) != null ? _a : activeEl;
}
function Popover(props) {
  const styles = useMultiStyleConfig("Popover", props);
  const { children, ...rest } = omitThemingProps(props);
  const theme = useTheme();
  const context = usePopover({ ...rest, direction: theme.direction });
  return /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverProvider, { value: context, children: /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverStylesProvider, { value: styles, children: runIfFn(children, {
    isOpen: context.isOpen,
    onClose: context.onClose,
    forceUpdate: context.forceUpdate
  }) }) });
}
Popover.displayName = "Popover";
var resolveVar = (scale, value) => value ? `${scale}.${value}, ${value}` : void 0;
function PopoverArrow(props) {
  var _a;
  const { bg, bgColor, backgroundColor, shadow, boxShadow, shadowColor } = props;
  const { getArrowProps, getArrowInnerProps } = usePopoverContext();
  const styles = usePopoverStyles();
  const arrowBg = (_a = bg != null ? bg : bgColor) != null ? _a : backgroundColor;
  const arrowShadow = shadow != null ? shadow : boxShadow;
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    chakra.div,
    {
      ...getArrowProps(),
      className: "chakra-popover__arrow-positioner",
      children: /* @__PURE__ */ jsxRuntimeExports.jsx(
        chakra.div,
        {
          className: cx("chakra-popover__arrow", props.className),
          ...getArrowInnerProps(props),
          __css: {
            "--popper-arrow-shadow-color": resolveVar("colors", shadowColor),
            "--popper-arrow-bg": resolveVar("colors", arrowBg),
            "--popper-arrow-shadow": resolveVar("shadows", arrowShadow),
            ...styles.arrow
          }
        }
      )
    }
  );
}
PopoverArrow.displayName = "PopoverArrow";
var PopoverBody = forwardRef(
  function PopoverBody2(props, ref) {
    const { getBodyProps } = usePopoverContext();
    const styles = usePopoverStyles();
    return /* @__PURE__ */ jsxRuntimeExports.jsx(
      chakra.div,
      {
        ...getBodyProps(props, ref),
        className: cx("chakra-popover__body", props.className),
        __css: styles.body
      }
    );
  }
);
PopoverBody.displayName = "PopoverBody";
function mergeVariants(variants) {
  if (!variants)
    return;
  return {
    enter: {
      ...variants.enter,
      visibility: "visible"
    },
    exit: {
      ...variants.exit,
      transitionEnd: {
        visibility: "hidden"
      }
    }
  };
}
var scaleFade = {
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.1,
      ease: [0.4, 0, 1, 1]
    }
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: [0, 0, 0.2, 1]
    }
  }
};
var MotionSection = chakra(motion.section);
var PopoverTransition = forwardRef(function PopoverTransition2(props, ref) {
  const { variants = scaleFade, ...rest } = props;
  const { isOpen } = usePopoverContext();
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    MotionSection,
    {
      ref,
      variants: mergeVariants(variants),
      initial: false,
      animate: isOpen ? "enter" : "exit",
      ...rest
    }
  );
});
PopoverTransition.displayName = "PopoverTransition";
var PopoverContent = forwardRef(
  function PopoverContent2(props, ref) {
    const { rootProps, motionProps, ...contentProps } = props;
    const { getPopoverProps, getPopoverPositionerProps, onAnimationComplete } = usePopoverContext();
    const styles = usePopoverStyles();
    const contentStyles = {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      ...styles.content
    };
    return /* @__PURE__ */ jsxRuntimeExports.jsx(
      chakra.div,
      {
        ...getPopoverPositionerProps(rootProps),
        __css: styles.popper,
        className: "chakra-popover__popper",
        children: /* @__PURE__ */ jsxRuntimeExports.jsx(
          PopoverTransition,
          {
            ...motionProps,
            ...getPopoverProps(contentProps, ref),
            onAnimationComplete: callAll(
              onAnimationComplete,
              contentProps.onAnimationComplete
            ),
            className: cx("chakra-popover__content", props.className),
            __css: contentStyles
          }
        )
      }
    );
  }
);
PopoverContent.displayName = "PopoverContent";
var fallbackIcon = {
  path: /* @__PURE__ */ jsxRuntimeExports.jsxs("g", { stroke: "currentColor", strokeWidth: "1.5", children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      "path",
      {
        strokeLinecap: "round",
        fill: "none",
        d: "M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
      }
    ),
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      "path",
      {
        fill: "currentColor",
        strokeLinecap: "round",
        d: "M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
      }
    ),
    /* @__PURE__ */ jsxRuntimeExports.jsx("circle", { fill: "none", strokeMiterlimit: "10", cx: "12", cy: "12", r: "11.25" })
  ] }),
  viewBox: "0 0 24 24"
};
var Icon = forwardRef((props, ref) => {
  const {
    as: element,
    viewBox,
    color = "currentColor",
    focusable = false,
    children,
    className,
    __css,
    ...rest
  } = props;
  const _className = cx("chakra-icon", className);
  const customStyles = useStyleConfig("Icon", props);
  const styles = {
    w: "1em",
    h: "1em",
    display: "inline-block",
    lineHeight: "1em",
    flexShrink: 0,
    color,
    ...__css,
    ...customStyles
  };
  const shared = {
    ref,
    focusable,
    className: _className,
    __css: styles
  };
  const _viewBox = viewBox != null ? viewBox : fallbackIcon.viewBox;
  if (element && typeof element !== "string") {
    return /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.svg, { as: element, ...shared, ...rest });
  }
  const _path = children != null ? children : fallbackIcon.path;
  return /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.svg, { verticalAlign: "middle", viewBox: _viewBox, ...shared, ...rest, children: _path });
});
Icon.displayName = "Icon";
var [TagStylesProvider, useTagStyles] = createContext({
  name: `TagStylesContext`,
  errorMessage: `useTagStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Tag />" `
});
var Tag = forwardRef((props, ref) => {
  const styles = useMultiStyleConfig("Tag", props);
  const ownProps = omitThemingProps(props);
  const containerStyles = {
    display: "inline-flex",
    verticalAlign: "top",
    alignItems: "center",
    maxWidth: "100%",
    ...styles.container
  };
  return /* @__PURE__ */ jsxRuntimeExports.jsx(TagStylesProvider, { value: styles, children: /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.span, { ref, ...ownProps, __css: containerStyles }) });
});
Tag.displayName = "Tag";
var TagLabel = forwardRef((props, ref) => {
  const styles = useTagStyles();
  return /* @__PURE__ */ jsxRuntimeExports.jsx(chakra.span, { ref, noOfLines: 1, ...props, __css: styles.label });
});
TagLabel.displayName = "TagLabel";
var TagLeftIcon = forwardRef((props, ref) => /* @__PURE__ */ jsxRuntimeExports.jsx(Icon, { ref, verticalAlign: "top", marginEnd: "0.5rem", ...props }));
TagLeftIcon.displayName = "TagLeftIcon";
var TagRightIcon = forwardRef((props, ref) => /* @__PURE__ */ jsxRuntimeExports.jsx(Icon, { ref, verticalAlign: "top", marginStart: "0.5rem", ...props }));
TagRightIcon.displayName = "TagRightIcon";
var TagCloseIcon = (props) => /* @__PURE__ */ jsxRuntimeExports.jsx(Icon, { verticalAlign: "inherit", viewBox: "0 0 512 512", ...props, children: /* @__PURE__ */ jsxRuntimeExports.jsx(
  "path",
  {
    fill: "currentColor",
    d: "M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"
  }
) });
TagCloseIcon.displayName = "TagCloseIcon";
var TagCloseButton = forwardRef(
  (props, ref) => {
    const { isDisabled, children, ...rest } = props;
    const styles = useTagStyles();
    const btnStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "0",
      ...styles.closeButton
    };
    return /* @__PURE__ */ jsxRuntimeExports.jsx(
      chakra.button,
      {
        ref,
        "aria-label": "close",
        ...rest,
        type: "button",
        disabled: isDisabled,
        __css: btnStyles,
        children: children || /* @__PURE__ */ jsxRuntimeExports.jsx(TagCloseIcon, {})
      }
    );
  }
);
TagCloseButton.displayName = "TagCloseButton";
function ErrorWarningPopovers({ warningsObjs, errorsObjs }) {
  return /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
    /* @__PURE__ */ jsxRuntimeExports.jsxs(Popover, { offset: [119, 5], children: [
      /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverTrigger, { children: /* @__PURE__ */ jsxRuntimeExports.jsxs(
        Tag,
        {
          m: "4px 2px 4px 8px",
          "data-test": "Warning Button",
          tabIndex: "0",
          cursor: "pointer",
          size: "md",
          colorScheme: warningsObjs.length == 0 ? "blackAlpha" : "yellow",
          children: [
            warningsObjs.length,
            " Warning",
            warningsObjs.length != 1 && "s"
          ]
        }
      ) }),
      warningsObjs.length == 0 ? /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverContent, { "data-test": "Warning Content", children: /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverHeader, { fontWeight: "semibold", children: "No Warnings" }) }) : /* @__PURE__ */ jsxRuntimeExports.jsxs(PopoverContent, { "data-test": "Warning Content", children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverArrow, {}),
        /* @__PURE__ */ jsxRuntimeExports.jsxs(PopoverHeader, { fontWeight: "semibold", children: [
          "Warning",
          warningsObjs.length != 1 && "s"
        ] }),
        /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverBody, { maxH: "40vh", overflow: "scroll", children: /* @__PURE__ */ jsxRuntimeExports.jsx(List, { spacing: 2, children: warningsObjs.map((warningObj, i) => {
          var _a;
          return /* @__PURE__ */ jsxRuntimeExports.jsxs(
            ListItem,
            {
              "data-test": `Warning ${i}`,
              children: [
                /* @__PURE__ */ jsxRuntimeExports.jsx(
                  ListIcon,
                  {
                    as: WarningTwoIcon,
                    color: "yellow.400",
                    marginBottom: "2px"
                  }
                ),
                "Line #",
                (_a = warningObj == null ? void 0 : warningObj.doenetMLrange) == null ? void 0 : _a.lineBegin,
                " ",
                warningObj.message
              ]
            },
            i
          );
        }) }) })
      ] })
    ] }),
    /* @__PURE__ */ jsxRuntimeExports.jsxs(Popover, { offset: [119, 5], children: [
      /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverTrigger, { children: /* @__PURE__ */ jsxRuntimeExports.jsxs(
        Tag,
        {
          m: "4px 8px 4px 8px",
          "data-test": "Error Button",
          tabIndex: "0",
          cursor: "pointer",
          size: "md",
          colorScheme: errorsObjs.length == 0 ? "blackAlpha" : "red",
          children: [
            errorsObjs.length,
            " Error",
            errorsObjs.length != 1 && "s"
          ]
        }
      ) }),
      errorsObjs.length == 0 ? /* @__PURE__ */ jsxRuntimeExports.jsxs(PopoverContent, { "data-test": "Error Content", children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverArrow, {}),
        /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverHeader, { fontWeight: "semibold", children: "No Errors" })
      ] }) : /* @__PURE__ */ jsxRuntimeExports.jsxs(PopoverContent, { "data-test": "Error Content", children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverArrow, {}),
        /* @__PURE__ */ jsxRuntimeExports.jsxs(PopoverHeader, { fontWeight: "semibold", children: [
          "Error",
          errorsObjs.length != 1 && "s"
        ] }),
        /* @__PURE__ */ jsxRuntimeExports.jsx(PopoverBody, { maxH: "40vh", overflow: "scroll", children: /* @__PURE__ */ jsxRuntimeExports.jsx(List, { spacing: 2, children: errorsObjs.map((errorObj, i) => {
          var _a;
          return /* @__PURE__ */ jsxRuntimeExports.jsxs(
            ListItem,
            {
              "data-test": `Error ${i}`,
              children: [
                /* @__PURE__ */ jsxRuntimeExports.jsx(
                  ListIcon,
                  {
                    as: WarningTwoIcon,
                    color: "red.500",
                    marginBottom: "2px"
                  }
                ),
                "Line #",
                (_a = errorObj == null ? void 0 : errorObj.doenetMLrange) == null ? void 0 : _a.lineBegin,
                " ",
                errorObj.message
              ]
            },
            i
          );
        }) }) })
      ] })
    ] })
  ] });
}
const codeEditor = React__default.memo(function CodeEditor(props) {
  let {
    name,
    id,
    SVs,
    children,
    actions,
    ignoreUpdate,
    rendererName,
    callAction
  } = useDoenetRenderer(props);
  CodeEditor.baseStateVariable = "immediateValue";
  const setRendererState = Recoil_index_24(rendererState(rendererName));
  let currentValue = useRef(SVs.immediateValue);
  let updateValueTimer = useRef(null);
  let editorRef = useRef(null);
  let updateInternalValueTo = useRef(SVs.immediateValue);
  let componentHeight = { ...SVs.height };
  let editorHeight = { ...SVs.height };
  if (SVs.showResults && SVs.resultsLocation === "bottom") {
    editorHeight.size *= 1 - SVs.viewerRatio;
  }
  let onChangeVisibility = (isVisible) => {
    callAction({
      action: actions.recordVisibilityChange,
      args: { isVisible }
    });
  };
  useEffect(() => {
    return () => {
      callAction({
        action: actions.recordVisibilityChange,
        args: { isVisible: false }
      });
      if (updateValueTimer.current !== null) {
        clearTimeout(updateValueTimer.current);
        callAction({
          action: actions.updateValue,
          baseVariableValue: currentValue.current
        });
      }
    };
  }, []);
  if (SVs.hidden) {
    return null;
  }
  useEffect(() => {
    let platform = "Linux";
    if (navigator.platform.indexOf("Win") != -1) {
      platform = "Win";
    } else if (navigator.platform.indexOf("Mac") != -1) {
      platform = "Mac";
    }
    const handleEditorKeyDown = (event) => {
      if (platform == "Mac" && event.metaKey && event.code === "KeyS" || platform != "Mac" && event.ctrlKey && event.code === "KeyS") {
        event.preventDefault();
        event.stopPropagation();
        clearTimeout(updateValueTimer.current);
        callAction({
          action: actions.updateValue,
          baseVariableValue: currentValue.current
        });
        updateValueTimer.current = null;
        callAction({ action: actions.updateComponents });
      }
    };
    let codeEditorContainer = document.getElementById(id);
    if (SVs.showResults) {
      codeEditorContainer.addEventListener(
        "keydown",
        handleEditorKeyDown
      );
    }
    return () => {
      codeEditorContainer.removeEventListener(
        "keydown",
        handleEditorKeyDown
      );
    };
  }, [SVs.showResults]);
  const editorKey = id + "_editor";
  const viewerKey = id + "_viewer";
  if (!ignoreUpdate && SVs.immediateValue !== currentValue.current) {
    currentValue.current = SVs.immediateValue;
    updateInternalValueTo.current = SVs.immediateValue;
  }
  let viewer = null;
  let editorWidth = SVs.width;
  let componentWidth = SVs.width;
  if (SVs.showResults && ["left", "right"].includes(SVs.resultsLocation)) {
    editorWidth = { size: 100, isAbsolute: false };
  }
  let editorStyle = {
    width: sizeToCSS(editorWidth),
    height: sizeToCSS(editorHeight),
    maxWidth: "100%",
    padding: "0px",
    overflowX: "hidden",
    overflowY: "hidden"
  };
  if (SVs.showResults) {
    if (SVs.resultsLocation === "bottom") {
      viewer = /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
        /* @__PURE__ */ jsxRuntimeExports.jsx(
          "hr",
          {
            style: {
              width: sizeToCSS(componentWidth),
              maxWidth: "100%"
            }
          }
        ),
        /* @__PURE__ */ jsxRuntimeExports.jsx("div", { id: viewerKey, children })
      ] });
    } else {
      viewer = /* @__PURE__ */ jsxRuntimeExports.jsx("div", { id: viewerKey, children });
    }
  }
  let paddingBottom = { ...editorHeight };
  paddingBottom.size /= 2;
  paddingBottom = sizeToCSS(paddingBottom);
  let errorsAndWarnings = null;
  let errorsAndWarningsHeight = 0;
  if (SVs.errorsAndWarnings) {
    errorsAndWarningsHeight = 32;
    const warningsLevel = 1;
    const warningsObjs = SVs.errorsAndWarnings.warnings.filter(
      (w) => w.level <= warningsLevel
    );
    const errorsObjs = [...SVs.errorsAndWarnings.errors];
    errorsAndWarnings = /* @__PURE__ */ jsxRuntimeExports.jsx(Flex, { ml: "0px", h: "32px", bg: "doenet.mainGray", pl: "10px", pt: "1px", children: /* @__PURE__ */ jsxRuntimeExports.jsx(
      ErrorWarningPopovers,
      {
        warningsObjs,
        errorsObjs
      }
    ) });
  }
  let editor = /* @__PURE__ */ jsxRuntimeExports.jsxs("div", { id: editorKey, style: editorStyle, children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      Box,
      {
        height: `calc(${sizeToCSS(
          editorHeight
        )} - ${errorsAndWarningsHeight}px)`,
        w: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        children: /* @__PURE__ */ jsxRuntimeExports.jsx(
          CodeMirror,
          {
            editorRef,
            setInternalValueTo: updateInternalValueTo.current,
            readOnly: SVs.disabled,
            onBlur: () => {
              clearTimeout(updateValueTimer.current);
              callAction({
                action: actions.updateValue,
                baseVariableValue: currentValue.current
              });
              updateValueTimer.current = null;
            },
            onFocus: () => {
            },
            onBeforeChange: (value) => {
              if (currentValue.current !== value) {
                currentValue.current = value;
                setRendererState((was) => {
                  let newObj = { ...was };
                  newObj.ignoreUpdate = true;
                  return newObj;
                });
                callAction({
                  action: actions.updateImmediateValue,
                  args: { text: value },
                  baseVariableValue: value
                });
                clearTimeout(updateValueTimer.current);
                updateValueTimer.current = setTimeout(function() {
                  callAction({
                    action: actions.updateValue,
                    baseVariableValue: currentValue.current
                  });
                  updateValueTimer.current = null;
                }, 3e3);
              }
            },
            paddingBottom
          }
        )
      }
    ),
    errorsAndWarnings
  ] }, editorKey);
  let editorWithViewer = editor;
  if (SVs.showResults && SVs.resultsLocation === "bottom") {
    editorWithViewer = /* @__PURE__ */ jsxRuntimeExports.jsxs(jsxRuntimeExports.Fragment, { children: [
      editor,
      viewer
    ] });
  }
  editorWithViewer = /* @__PURE__ */ jsxRuntimeExports.jsxs("div", { style: { margin: "12px 0" }, children: [
    /* @__PURE__ */ jsxRuntimeExports.jsx("a", { name: id }),
    /* @__PURE__ */ jsxRuntimeExports.jsx(
      "div",
      {
        style: {
          padding: "0",
          border: "var(--mainBorder)",
          borderRadius: "var(--mainBorderRadius)",
          height: sizeToCSS(componentHeight),
          width: sizeToCSS(editorWidth),
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column"
        },
        id,
        children: editorWithViewer
      }
    )
  ] });
  if (SVs.showResults) {
    if (SVs.resultsLocation === "left") {
      let viewerPercent = SVs.viewerRatio * 100;
      let editorPercent = 100 - viewerPercent;
      editorWithViewer = /* @__PURE__ */ jsxRuntimeExports.jsxs(
        "div",
        {
          style: {
            display: "flex",
            width: sizeToCSS(componentWidth),
            maxWidth: "100%",
            margin: "12px 0"
          },
          children: [
            /* @__PURE__ */ jsxRuntimeExports.jsx(
              "div",
              {
                style: {
                  maxWidth: `${viewerPercent}%`,
                  paddingRight: "15px",
                  boxSizing: "border-box"
                },
                children: viewer
              }
            ),
            /* @__PURE__ */ jsxRuntimeExports.jsx(
              "div",
              {
                style: {
                  width: `${editorPercent}%`,
                  boxSizing: "border-box"
                },
                children: editorWithViewer
              }
            )
          ]
        }
      );
    } else if (SVs.resultsLocation === "right") {
      let viewerPercent = SVs.viewerRatio * 100;
      let editorPercent = 100 - viewerPercent;
      editorWithViewer = /* @__PURE__ */ jsxRuntimeExports.jsxs(
        "div",
        {
          style: {
            display: "flex",
            width: sizeToCSS(componentWidth),
            maxWidth: "100%",
            margin: "12px 0"
          },
          children: [
            /* @__PURE__ */ jsxRuntimeExports.jsx(
              "div",
              {
                style: {
                  width: `${editorPercent}%`,
                  paddingRight: "15px",
                  boxSizing: "border-box"
                },
                children: editorWithViewer
              }
            ),
            /* @__PURE__ */ jsxRuntimeExports.jsx(
              "div",
              {
                style: {
                  width: `${viewerPercent}%`,
                  boxSizing: "border-box"
                },
                children: viewer
              }
            )
          ]
        }
      );
    }
  }
  return /* @__PURE__ */ jsxRuntimeExports.jsx(
    VisibilitySensor,
    {
      partialVisibility: true,
      onChange: onChangeVisibility,
      children: editorWithViewer
    }
  );
});
export {
  codeEditor as default
};
